
export function minEnclosingCircle(points) {
    if (points.length === 0) return null;

    function dist(a, b) {
        return Math.hypot(a.x - b.x, a.y - b.y);
    }

    function circleFromTwoPoints(p1, p2) {
        const cx = (p1.x + p2.x) / 2;
        const cy = (p1.y + p2.y) / 2;
        const r = dist(p1, p2) / 2;
        return { x: cx, y: cy, r };
    }

    function circleFromThreePoints(p1, p2, p3) {
        const A = p1.x - p3.x, B = p1.y - p3.y;
        const C = p2.x - p3.x, D = p2.y - p3.y;
        const E = A * (p1.x + p3.x) + B * (p1.y + p3.y);
        const F = C * (p2.x + p3.x) + D * (p2.y + p3.y);
        const G = 2 * (A * (p2.y - p3.y) - B * (p2.x - p3.x));
        if (G === 0) return null;

        const cx = (D * E - B * F) / G;
        const cy = (A * F - C * E) / G;
        const r = dist({ x: cx, y: cy }, p1);
        return { x: cx, y: cy, r };
    }

    function isInCircle(circle, p) {
        return dist(circle, p) <= circle.r + 1e-9;
    }

    function welzl(points, boundary = []) {
        if (points.length === 0 || boundary.length === 3) {
            if (boundary.length === 0) return { x: 0, y: 0, r: 0 };
            if (boundary.length === 1) return { x: boundary[0].x, y: boundary[0].y, r: 5 };
            if (boundary.length === 2) return circleFromTwoPoints(boundary[0], boundary[1]);
            return circleFromThreePoints(boundary[0], boundary[1], boundary[2]);
        }

        const p = points.pop();
        const circle = welzl([...points], boundary);
        if (isInCircle(circle, p)) return circle;
        return welzl([...points], [...boundary, p]);
    }

    return welzl([...points]);
}
