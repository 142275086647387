import {useState} from "react";

import MapGrid from "../generalMapVisualizer/MapGrid";
import Menu from "./Menu";
import ScalableSVG from "./ScalableSvg";
import PlainHistoryPlot from "./PlainHistoryPlot";
import MapSwitcher from './MapSwitcher';
import ClusteredHistoryPlot from "./ClusteredHistoryPlot";

import "./mapHistoryVisualizer.css";


export default function ({data}) {
    const MIN_DASH_TIME_INTERVAL = 60_000;  // solid strokes is less than one minute
    const MAX_DASH_TIME_INTERVAL = 600_000;  // very sparse strokes is greater than ten minute
    const DASH_TIME_STEP = 30_000;  // for each 30 seconds stroke dash will grow

    const maps = data ? JSON.parse(data) : [[[], 'No name']];

    const [currentMapIndex, setCurrentMapIndex] = useState(0);
    const [shouldShowLines, setShouldShowLines] = useState(false);
    const [shouldClusterize, setShouldClusterize] = useState(false);
    const [maxDistToClusterize, setMaxDistToClusterize] = useState(100);
    const [maxTimeDiffToClusterize, setMaxTimeDiffToClusterize] = useState(60); // seconds

    return <div>
        <MapSwitcher
            currentMapIndex={currentMapIndex}
            setCurrentMapIndex={setCurrentMapIndex}
            maps={maps}
        />
        <div style={{'display': 'flex'}}>
            <ScalableSVG>
                <svg className="map-history-visualizer-svg-container">
                    <MapGrid/>
                    {
                        shouldClusterize
                        ? <ClusteredHistoryPlot
                            maps={maps}
                            currentMapIndex={currentMapIndex}
                            shouldShowLines={shouldShowLines}
                            MIN_DASH_TIME_INTERVAL={MIN_DASH_TIME_INTERVAL}
                            MAX_DASH_TIME_INTERVAL={MAX_DASH_TIME_INTERVAL}
                            DASH_TIME_STEP={DASH_TIME_STEP}
                            maxDistToClusterize={maxDistToClusterize}
                            maxTimeDiffToClusterize={maxTimeDiffToClusterize * 1000}
                        />
                        : <PlainHistoryPlot
                            maps={maps}
                            currentMapIndex={currentMapIndex}
                            shouldShowLines={shouldShowLines}
                            MIN_DASH_TIME_INTERVAL={MIN_DASH_TIME_INTERVAL}
                            MAX_DASH_TIME_INTERVAL={MAX_DASH_TIME_INTERVAL}
                            DASH_TIME_STEP={DASH_TIME_STEP}
                        />
                    }
                </svg>
            </ScalableSVG>
            <Menu
                shouldShowLines={shouldShowLines}
                setShouldShowLines={setShouldShowLines}
                shouldClusterize={shouldClusterize}
                setShouldClusterize={setShouldClusterize}
                maxDistToClusterize={maxDistToClusterize}
                setMaxDistToClusterize={setMaxDistToClusterize}
                maxTimeDiffToClusterize={maxTimeDiffToClusterize}
                setMaxTimeDiffToClusterize={setMaxTimeDiffToClusterize}
            />
        </div>
    </div>;
}
