

const PlotPoint = ({item, i, mapLength}) => {
    const colorRedChannel = Math.round(i / mapLength * 255);
    const colorGreenChannel = 255 - colorRedChannel;
    const colorBlueChannel = 0;
    const color = `rgb(${colorRedChannel}, ${colorGreenChannel}, ${colorBlueChannel})`;
    return <>
        <circle cx={item.x} cy={item.y} r='5' fill={color}/>
        <text x={item.x} y={item.y}>{i}</text>
    </>;
};


const PlotLine = ({
                      currentMap,
                      i,
                      MIN_DASH_TIME_INTERVAL,
                      MAX_DASH_TIME_INTERVAL,
                      DASH_TIME_STEP,
                  }) => {
    const colorRedChannel = Math.round(i / currentMap.length * 255);
    const colorGreenChannel = 255 - colorRedChannel;
    const colorBlueChannel = 0;
    const color = `rgb(${colorRedChannel}, ${colorGreenChannel}, ${colorBlueChannel})`;

    return <line
        x1={currentMap[i]?.x}
        y1={currentMap[i]?.y}
        x2={currentMap[i + 1]?.x}
        y2={currentMap[i + 1]?.y}
        strokeDasharray={
            currentMap[i + 1]?.createdAt - currentMap[i]?.createdAt < MIN_DASH_TIME_INTERVAL
            ? 0
            : (
                currentMap[i + 1]?.createdAt - currentMap[i]?.createdAt > MAX_DASH_TIME_INTERVAL
                ? 25
                : (currentMap[i + 1]?.createdAt - currentMap[i]?.createdAt) / DASH_TIME_STEP
            )
        }
        stroke={color}
    />;
};


export default function ({
                             maps,
                             currentMapIndex,
                             shouldShowLines,
                             MIN_DASH_TIME_INTERVAL,
                             MAX_DASH_TIME_INTERVAL,
                             DASH_TIME_STEP,
                         }) {
    const currentMap = maps[currentMapIndex][0];

    return <>
        {(currentMap || []).map(
            (item, i) => <PlotPoint item={item} i={i} mapLength={currentMap.length}/>
        )}
        {shouldShowLines && (currentMap.slice(1) || []).map(
            (item, i) => <PlotLine
                currentMap={currentMap || []}
                i={i}
                MIN_DASH_TIME_INTERVAL={MIN_DASH_TIME_INTERVAL}
                MAX_DASH_TIME_INTERVAL={MAX_DASH_TIME_INTERVAL}
                DASH_TIME_STEP={DASH_TIME_STEP}
            />
        )}
    </>;
}
