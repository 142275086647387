import React, {useState, useEffect, useRef} from "react";

import {useLocalization} from "../../../../customHooks/useContextLocalization";
import {useReduxData} from "../../../customHooks/useContextReduxData";
import {useCurrentProject} from "../../../customHooks/useContextCurrentProject";
import hintModeTooltipHOC from "../../../onboarding/hintModeTooltipHOC";
import tooltipHOC from "../../../../components/tooltipHOC";


const MenuItemTitle = hintModeTooltipHOC(({title}) => title);


const MenuSubItemTitle = tooltipHOC(
    ({item, subitem, enableItemConditions, currentProject, locale}) => (
        <p
            className={`top-menu-subitem${(!subitem.enabled
                || (typeof subitem.enabled === "function" && !subitem.enabled(enableItemConditions))
            ) ? "-disabled" : ""}`}
            id={`top-menu-${item.key}-${subitem.key}`}
            onClick={subitem.enabled ? subitem.onClick : undefined}
        >
            {subitem.key === "send" && currentProject.reward?.onSaveMsg
                ? currentProject.reward?.onSaveMsg?.[locale.get.abbr.toLowerCase()]
                : locale?.get.studio.header.menu[item.key]?.items?.[subitem.key] || subitem.key
            }
        </p>
    ),
    undefined,
    {direction: "right", padding: "10px"},
);


export default function TopMenuItem({item}) {
    const locale = useLocalization();
    const {get: currentProject} = useCurrentProject();
    const {
        isWatchMode,
        isThereFuture,
        isTherePast,
        isThereClipboardContent,
        isThereSelectedFigures
    } = useReduxData();

    const [isActive, setIsActive] = useState(false);
    const [xPosition, setXPosition] = useState(0);
    const [yPosition, setYPosition] = useState(0);

    const topMenuTitle = useRef(null);

    useEffect(() => {
        if (topMenuTitle) {
            let params = topMenuTitle.current.getBoundingClientRect();
            setXPosition(+params.left);
            setYPosition(+params.top);
        }
    }, [isActive, item.key]); //иначе шрифты не успевают подгрузиться и сдвиг неправильный

    const showSubitems = () => setIsActive(true);
    const hideSubitems = () => setIsActive(false);

    const hintModeKey = `menu${item.key[0].toUpperCase()}${item.key.slice(1)}`; //file -> menuFile etc...
    const enableItemConditions = {
        isTherePast,
        isThereFuture,
        isWatchMode,
        isThereSelectedFigures,
        isThereClipboardContent
    };

    return <>
        <li
            id={`top-menu-title-${item.key}`}
            ref={topMenuTitle}
            onClick={item.onClick || undefined}
            onMouseEnter={showSubitems}
            onMouseLeave={hideSubitems}
        ><MenuItemTitle title={locale?.get.studio.header.menu[item.key].title} hintModeKey={hintModeKey}/></li>
        {isActive && <>
            <div
                className={`top-menu-pseudo-title${item.items.length ? "" : "-rounded"}`}
                style={{marginLeft: xPosition + "px", marginTop: yPosition + "px"}}
            >{locale?.get.studio.header.menu[item.key].title}</div>
            {!!item.items.length && <div
                className="top-menu-item"
                style={{
                    marginLeft: xPosition + "px",
                    marginTop: (yPosition + 39) / 16 + "rem",
                    width: item.isExtended ? "18.4375rem" : "auto"
                }}
                onMouseEnter={showSubitems}
                onMouseLeave={hideSubitems}
                onClick={hideSubitems}
            >
                {item.items.map(subitem =>
                    <div key={`subitem-${subitem.key}`} className="top-menu-subitem-container">
                        <MenuSubItemTitle
                            item={item}
                            subitem={subitem}
                            enableItemConditions={enableItemConditions}
                            currentProject={currentProject}
                            locale={locale}
                            tooltipContent={
                                subitem.key === 'send'
                                ? locale?.get.studio.header.sendMapTooltip
                                : undefined
                        }
                        />
                        {subitem.hotkey && <p className={`top-menu-hot-key${(!subitem.enabled
                                || (typeof subitem.enabled === "function" && !subitem.enabled(enableItemConditions))
                        ) ? "-disabled" : ""}`}>
                            {subitem.hotkey}
                        </p>}
                    </div>
                )}
            </div>}
        </>}
    </>;
}
