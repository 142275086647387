import {minEnclosingCircle} from "./utils";


const PlotCluster = ({cluster, mapLength}) => {
    const firstColorRedChannel = Math.round(cluster.firstIndex / mapLength * 255);
    const firstColorGreenChannel = 255 - firstColorRedChannel;
    const firstColorBlueChannel = 0;
    const firstColor = `rgb(${firstColorRedChannel}, ${firstColorGreenChannel}, ${firstColorBlueChannel})`;

    const lastColorRedChannel = Math.round(cluster.lastIndex / mapLength * 255);
    const lastColorGreenChannel = 255 - lastColorRedChannel;
    const lastColorBlueChannel = 0;
    const lastColor = `rgb(${lastColorRedChannel}, ${lastColorGreenChannel}, ${lastColorBlueChannel})`;

    const circleParams = minEnclosingCircle(cluster.points);

    return <>
        <defs>
            <radialGradient id={`cluster-gradient-${cluster.firstIndex}`}>
                <stop offset="5%" stopColor={firstColor}/>
                <stop offset="95%" stopColor={lastColor}/>
            </radialGradient>
        </defs>

        <circle
            cx={circleParams.x}
            cy={circleParams.y}
            r={circleParams.r}
            fill={`url('#cluster-gradient-${cluster.firstIndex}')`}
        />
        <text x={circleParams.x} y={circleParams.y}>
            {/*{`ind: ${cluster.firstIndex} - ${cluster.lastIndex}; t: ${cluster.firstCreatedAt} - ${cluster.lastCreatedAt}`}*/}
            {`${cluster.firstIndex} - ${cluster.lastIndex}`}
        </text>
    </>;
};


const PlotLine = ({
                      currentMap,
                      clusteredMap,
                      i,
                      MIN_DASH_TIME_INTERVAL,
                      MAX_DASH_TIME_INTERVAL,
                      DASH_TIME_STEP,
                  }) => {
    const currentCluster = clusteredMap[i];

    const colorRedChannel = Math.round(currentCluster.lastIndex / currentMap.length * 255);
    const colorGreenChannel = 255 - colorRedChannel;
    const colorBlueChannel = 0;
    const color = `rgb(${colorRedChannel}, ${colorGreenChannel}, ${colorBlueChannel})`;

    // TODO: maybe we should compute dist between the center of a cluster
    return <line
        x1={currentCluster.points[currentCluster.points.length - 1].x}
        y1={currentCluster.points[currentCluster.points.length - 1].y}
        x2={clusteredMap[i + 1].points[0].x}
        y2={clusteredMap[i + 1].points[0].y}
        strokeDasharray={
            clusteredMap[i + 1].firstCreatedAt - currentCluster.lastCreatedAt < MIN_DASH_TIME_INTERVAL
            ? 0
            : (
                clusteredMap[i + 1].firstCreatedAt - currentCluster.lastCreatedAt > MAX_DASH_TIME_INTERVAL
                ? 25
                : (clusteredMap[i + 1].firstCreatedAt - currentCluster.lastCreatedAt) / DASH_TIME_STEP
            )
        }
        stroke={color}
    />;
};


const getMinDistanceToCluster = (clusterPoints, newPoint) => {
    let minDist = 0;
    for (let i = 0; i < clusterPoints.length; i++) {
        let currentDist = Math.sqrt(
            Math.pow(newPoint.x - clusterPoints[i].x, 2)
            + Math.pow(newPoint.y - clusterPoints[i].y, 2)
        );
        if (minDist === 0 || currentDist < minDist) {
            minDist = currentDist;
        }
    }

    return minDist;
};


export default function ({
                             maps,
                             currentMapIndex,
                             shouldShowLines,
                             MIN_DASH_TIME_INTERVAL,
                             MAX_DASH_TIME_INTERVAL,
                             DASH_TIME_STEP,
                             maxDistToClusterize,
                             maxTimeDiffToClusterize,
                         }) {
    const currentMap = maps[currentMapIndex][0];

    let clusteredMap = [];
    if (currentMap.length > 0) {
        clusteredMap = [
            {
                points: [{x: currentMap[0].x, y: currentMap[0].y,}],
                firstIndex: 0,
                lastIndex: 0,
                firstCreatedAt: currentMap[0].createdAt,
                lastCreatedAt: currentMap[0].createdAt,
            }
        ];
    }

    for (let i = 1; i < currentMap.length; i++) {
        let lastCluster = clusteredMap[clusteredMap.length - 1];
        let item = currentMap[i];
        let currentPointDist = getMinDistanceToCluster(lastCluster.points, item);
        let timeDiff = item.createdAt - lastCluster.lastCreatedAt;
        if (currentPointDist < maxDistToClusterize && timeDiff < maxTimeDiffToClusterize) {
            lastCluster.points.push({x: item.x, y: item.y});
            lastCluster.lastIndex = i;
            lastCluster.lastCreatedAt = item.createdAt;
        } else {
            clusteredMap.push(
                {
                    points: [{x: item.x, y: item.y,}],
                    firstIndex: i,
                    lastIndex: i,
                    firstCreatedAt: item.createdAt,
                    lastCreatedAt: item.createdAt,
                }
            );
        }
    }

    return <>
        {(clusteredMap || []).map(
            cluster => <PlotCluster cluster={cluster} mapLength={currentMap.length}/>
        )}
        {shouldShowLines && (clusteredMap.slice(1) || []).map(
            (item, i) => <PlotLine
                currentMap={currentMap || []}
                clusteredMap={clusteredMap}
                i={i}
                MIN_DASH_TIME_INTERVAL={MIN_DASH_TIME_INTERVAL}
                MAX_DASH_TIME_INTERVAL={MAX_DASH_TIME_INTERVAL}
                DASH_TIME_STEP={DASH_TIME_STEP}
            />
        )}
    </>;
}
