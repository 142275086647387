

export default function ({
                             shouldShowLines,
                             setShouldShowLines,
                             shouldClusterize,
                             setShouldClusterize,
                             maxDistToClusterize,
                             setMaxDistToClusterize,
                             maxTimeDiffToClusterize,
                             setMaxTimeDiffToClusterize,
                         }) {
    const switchShouldShowLines = () => setShouldShowLines(!shouldShowLines);
    const switchShouldClusterize = () => setShouldClusterize(!shouldClusterize);

    const changeMaxDistToClusterize = e => setMaxDistToClusterize(e.target.value);
    const changeMaxTimeDiffToClusterize = e => setMaxTimeDiffToClusterize(e.target.value);

    return <div className="wfpm-p-visualizer-menu-container">
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <input type="checkbox" checked={shouldShowLines} onChange={switchShouldShowLines}/>
            <p>Соединить соседние точки</p>
        </div>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <input type="checkbox" checked={shouldClusterize} onChange={switchShouldClusterize}/>
            <p>Кластеризовать точки</p>
        </div>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <p>Максимальное расстояние для кластеризации</p>
            <input
                type="text"
                placeholder="100"
                value={maxDistToClusterize}
                onChange={changeMaxDistToClusterize}
                style={{width: "3rem"}}
            />
        </div>
        <div className="wfpm-p-visualizer-menu-dispersion-container">
            <p>Максимальное разница времени для кластеризации (секунд)</p>
            <input
                type="text"
                placeholder="60"
                value={maxTimeDiffToClusterize}
                onChange={changeMaxTimeDiffToClusterize}
                style={{width: "3rem"}}
            />
        </div>
    </div>;
}
