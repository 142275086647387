

export default function ({currentMapIndex, setCurrentMapIndex, maps}) {
    const showNextMap = () => setCurrentMapIndex((currentMapIndex + 1) % maps.length);
    const showPrevMap = () => setCurrentMapIndex((currentMapIndex - 1 + maps.length) % maps.length);

    return <div className='map-history-visualizer-map-selector-container'>
        <button className="map-history-visualizer-button" onClick={showPrevMap}>
            {'Назад'}
        </button>
        <h3>{maps[currentMapIndex][1].split('.')[0]}</h3>
        <button className="map-history-visualizer-button" onClick={showNextMap}>
            {'Далее'}
        </button>
    </div>;
}